import { Link } from "react-router-dom"

function Nav() {
  return (
    <nav className="nav">
        <Link><u>HOME</u></Link>
    </nav>
  )
}

export default Nav